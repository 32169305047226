import React, { createContext, useContext } from 'react';

const LocaleContext = createContext(); // Renamed localeContext to LocaleContext

export const LocaleProvider = ({ children, locale }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  return useContext(LocaleContext);
};