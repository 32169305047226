import styled from 'styled-components';

export const HeaderMain = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 50px ;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  z-index: 10;
  @media (max-width: 1023px) {
    padding: 8px 25px;
  }
    .hamburger {
    background: var(--white);
    position: relative;
    padding: 0;
    margin: 20px;
    height: 30px;
    width: 40px;
    @media (min-width: 1024px) {
      display: none;
    }
    span {
      display: block;
      position: absolute;
      left: 0;
      height: 2px;
      width: 100%;
      background: var(--black);
      border-radius: 2px;
      transition: all 0.2s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 14px;
      }
      &:nth-child(4) {
        top: 29px;
      }
    }

    &.open {
      span {
        &:nth-child(1) {
          top: 10px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 20px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
`;

export const NavStyles = styled.nav`
  @media (max-width: 1023px) {
    display: block;
    position: fixed;
    top: 86px;
    bottom: 0;
    left: 100%;
    width: 100%;
    background: var(--white);
    padding: 50px 40px;
    transition: all 0.5s ease;
    z-index: 99;

    &.open {
      left: 0%;
    }
    }
  ul {
    width: fit-content;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 2vw;
    align-items: center;
    
    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      gap: 45px;
    }
    
    li {
      position: relative;
      @media (min-width: 1024px) {
        flex: 1 1 auto;
      }
      
      &.language {
        padding: 0;
        font-weight: 300;
        
        @media (max-width: 1023px) {
          width: fit-content;
          margin: auto auto 0;
        }
        .active {
          padding: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 1.4px;
          text-shadow: 0 0 0.75px;
          text-transform: uppercase;
          pointer-events: none;
          
          &::after {
            content: '/';
            padding: 0 4px;
            font-weight: 300;
            text-shadow: unset;
          }
            
          @media (max-width: 1023px) {
            padding: 10px;
            background: var(--blue-main);
            color: var(--white);
            font-size: 24px;
            line-height: 32px;
            text-shadow: unset;
            font-weight: 600;
            display: inline-block;
            
            &::after {
              content: '';
            }
          }
        }
        .change-language {
          padding: 0px;
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 1.4px;
          &:hover {
            text-shadow: unset;
            color: var(--blue-dark);
          }
          @media (max-width: 1023px) {
            padding: 10px;
            background: var(--blue-light);
            color: var(--white);
            font-size: 24px;
            line-height: 32px;
            border-radius: 0px;
            
            &:hover {
              color: var(--white);
            }
          }
        }
      }
      .menu-text {
        padding: 0;
        color: var(--black);
        font-weight: 300;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 1.4px;
  
        &:hover {
          text-shadow: 0 0 0.75px;
        }
        @media (max-width: 1023px) {
          font-size: 24px;
          line-height: 32px;
        }
        &.active {
          font-weight: 600;
        }
        &.contact {
          @media (min-width: 1024px) {
            padding: 16px 20px;
            cursor: pointer;
            font-weight: 600;
            background-color: var(--blue-main);
            color: var(--white);
            transition: all 0.2s ease-in-out;
            &:focus-visible {
              outline: 1px solid var(--black);
            }
            &:hover {
              background-color: #4DC9F1;  
            }
          }
        }
      }
    }
  }
`;

export const LogoStyles = styled.div`

    display: inline-flex;
    cursor: pointer;
    .logoDesktop {
    display: block;
      @media (max-width: 1280px) {
        display: none;
      }
    }
    .logoMobile {
      display: none;
      @media (max-width: 1280px) {
        display: block;
    }
  }
`;