import React, { useState } from 'react';
import { useLocale } from './localeContext';
import {HeaderMain} from "../styles/HeaderStyles";
import Nav from "./Nav";
import Logo from "./Logo";


export default function Header({ data }) {



  const { locale } = useLocale();

  const [menuState, setMenuState] = useState(false);
  return (
    <HeaderMain>
      <Logo menuState={menuState} setMenuState={setMenuState} locale={locale} />
      <Nav menuState={menuState} setMenuState={setMenuState} locale={locale}  />
      <button
        type="button"
        aria-label={`Click to ${menuState ? 'close' : 'open'} mobile menu`}
        onClick={() => setMenuState(!menuState)}
        className={`hamburger ${menuState ? 'open' : ''}`}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </HeaderMain>
  );
}

